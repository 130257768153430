import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { VueSlickPopover, VueSlickPopoverContent } from "vue-slick-popover";
import "vue-slick-popover/dist/vue-slick-popover.css";
import "../../assets/css/global.css";
import { Location } from "@/models/location";
import { ReferenceData } from "@/models/reference-data";
import moment from "moment";
import "configurable-date-input-polyfill";

@Component({
  components: {
    VueSlickPopover,
    VueSlickPopoverContent
  }
})
export default class TopNavigation extends Vue {
  public departure: string = null;
  public destination: string = null;
  public departureDate: string = null;
  public returnDate: string = "";
  public departureDateValue = "";
  public returnDateValue = "";
  public departureArrival: string = null;
  public location: string = null;
  public bookTicketErrors: string = null;
  public journeyPlannerErrors: string = null;
  public departureArrivalErrors: string = null;
  public arrivalDepartureErrors: string = null;
  public bookTicketJourneyType = "return";
  public journeyPlannerJourneyType = "return";
  public locations: Array<Location> = null;
  public locationsReferenceData: Array<ReferenceData> = null;
  public stopsAndStationsData: Array<ReferenceData> = null;
  public passengerCounter = 1;
  public arrivingDepartingType = "departure";
  public stopLocation: string = null;
  public today: string;
  public shrinkWidget = true;
  private customLincBookingsUrl: string;
  public customLincUnavailable = false;
  public isMobileView = screen.width <= 800;
  public minDepartureDate: string = null;
  public minReturnDate: string = null;
  public maxDepartureDateError = false;
  public maxReturnDateError = false;
  public disruptionLocation: string = null;
  public disruptionMessage: string = null;
  public disruptionArticleUrl: string = null;
  public isDisruption: boolean = false;
  public stopsAndStation = "";

  @Watch("departureDate")
  onDepartureDateChanged(value: string, oldValue: string) {
    this.minReturnDate = moment(this.departureDate).format("YYYY-MM-DD");
    const maxDate = moment().add(90, "days");
    if (moment(this.departureDate).isAfter(maxDate)) {
      this.maxDepartureDateError = true;
    } else {
      this.maxDepartureDateError = false;
    }
  }

  @Watch("returnDate")
  onReturnDateChanged(value: string, oldValue: string) {
    const maxDate = moment().add(90, "days");
    if (moment(this.returnDate).isAfter(maxDate)) {
      this.maxReturnDateError = true;
    } else {
      this.maxReturnDateError = false;
    }
  }

  @Watch("stopsAndStation")
  public stopsAndStationsIsChanged() {
    this.stopsAndStationsData = this.locationsReferenceData.filter(loc => {
      if (
        loc.text
          .toLocaleLowerCase()
          .startsWith(this.stopsAndStation.toLocaleLowerCase()) === true
      ) {
        return loc;
      }
    });
  }

  public setSessionStroageForStopsAndStations() {
    sessionStorage.setItem("stops-and-station", this.stopsAndStation);
    this.stopsAndStation = "";
    window.location.pathname = "/plan-your-journey/journey-planner";
  }

  private checkIfUserIsBrowsingOnMobile() {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
    ];

    return toMatch.some(toMatchItem => {
      return navigator.userAgent.match(toMatchItem);
    });
  }

  public mounted(): void {
    if (this.checkIfUserIsBrowsingOnMobile() === true) {
      this.$store.dispatch("userAgentIsMobile");
    }
    this.$store.dispatch("fetchLocations").then(result => {
      this.locations = result as Array<Location>;
      this.locationsReferenceData = this.locations
        .map(x => {
          return {
            text: x.locationDesc,
            value: x.locationCode
          } as ReferenceData;
        })
        .sort((a, b) => (a.text < b.text ? -1 : a.text > b.text ? 1 : 0));
    });
    // TODO: read from  settings
    const href = window.location.href;
    //if (href === 'https://dev.944.transwa.wa.gov.au/#/' || href === 'https://dev.944.transwa.wa.gov.au/#' || href === 'https://dev.944.transwa.wa.gov.au/' || href === 'https://dev.944.transwa.wa.gov.au') {
    //    this.shrinkWidget = false;
    //}

    this.minDepartureDate = moment().format("YYYY-MM-DD");
    this.minReturnDate = moment().format("YYYY-MM-DD");

    window.addEventListener("heroBannerSettingsLoaded", async () => {
      this.customLincBookingsUrl = (<any>(
        window
      )).dnninjectable.customLincBookingsUrl;
      const customLincUnAvailableFromDate = (<any>window).dnninjectable
        .customLincUnAvailableFromDate;
      const customLincUnAvailableFromTime = (<any>window).dnninjectable
        .customLincUnAvailableFromTime;
      const customLincUnAvailableToDate = (<any>window).dnninjectable
        .customLincUnAvailableToDate;
      const customLincUnAvailableToTime = (<any>window).dnninjectable
        .customLincUnAvailableToTime;
      this.disruptionLocation = (<any>window).dnninjectable.disruptionLocation;
      this.disruptionMessage = (<any>window).dnninjectable.disruptionMessage;
      this.disruptionArticleUrl = (<any>(
        window
      )).dnninjectable.disruptionArticleUrl;
      const widgetExpand = (<any>window).dnninjectable.isExpand;
      this.isDisruption = (<any>window).dnninjectable.isDisruption;

      if (widgetExpand) {
        this.shrinkWidget = false;
        if (!this.isMobileView) {
          window.onclick = function(event) {
            var bookingWidget = document.getElementById("booking-widget");
            if (bookingWidget.contains(event.target as any)) return;
            if (
              (<HTMLInputElement>document.activeElement).hasAttribute(
                "data-has-picker"
              )
            )
              return; // date polyfill
            bookingWidget.classList.remove("widget--visible");
          };
        }
      }

      this.setCustomLincAvailability(
        customLincUnAvailableFromDate,
        customLincUnAvailableFromTime,
        customLincUnAvailableToDate,
        customLincUnAvailableToTime
      );

      this.$store.dispatch("fetchLocations").then(result => {
        this.locations = result as Array<Location>;
        this.locationsReferenceData = this.locations
          .map(x => {
            return {
              text: x.locationDesc,
              value: x.locationCode
            } as ReferenceData;
          })
          .sort((a, b) => (a.text < b.text ? -1 : a.text > b.text ? 1 : 0));

        var inBrowser = typeof window !== "undefined";
        var browser_UA = inBrowser && window.navigator.userAgent.toLowerCase();

        //if Firefox Android, switch to jquery ui autocomplete
        if (
          browser_UA.indexOf("firefox") > 0 &&
          browser_UA.indexOf("android") > 0
        ) {
          document.head.appendChild(
            document.createComment(" Firefox Android - datalist workaround ")
          );

          let customScript = document.createElement("script");

          customScript.text = `
                    $(document).ready(function () {
                        var i = 0;
                        $('input[list]').each(function () {
                            i += 1;
                            var locationOptions = $('#' + $(this).attr("list")).find('option').map(function () {
                                return this.value;
                            }).get();

                            if (!locationOptions.length) { return; }

                            $(this).autocomplete({
                                source: locationOptions,
                                minLength: 0,
                                appendTo: "#container" + i
                            }).focus(function () {
                                $(this).autocomplete("search");
                            });
                        });

                        // Remove all datalists from the DOM so they do not display.
                        $('datalist').remove();
                        $('.container').css({'display': 'block'});
                    });
                        `;

          document.head.appendChild(customScript);

          let style = document.createElement("link");

          style.type = "text/css";
          style.rel = "stylesheet";
          style.href =
            "https://code.jquery.com/ui/1.11.4/themes/smoothness/jquery-ui.css";

          document.head.appendChild(style);

          document.head.appendChild(
            document.createComment(
              " End Firefox Android - datalist workaround "
            )
          );
        }
      });
    });

    this.today = this.formatDateToYyyyMmDd(new Date());
    this.checkForParams();
  }

  public created(): void {}

  private fireInitiateTicketsGAEvent(): void {
    let gaDepartureDate: string = moment(this.departureDate).format(
      "DD/MM/YYYY"
    );

    let gaReturnDate: string = "";
    if (this.returnDate) {
      gaReturnDate = moment(this.returnDate).format("DD/MM/YYYY");
    }

    const ticketsTripType =
      this.bookTicketJourneyType === "return" ? "return" : "one way";

    type BookingsGtagEventData = {
      ticket_booking_stage: string;
      trip_type: string;
      depart_date: string;
      return_date: string;
      from_location: string;
      to_location: string;
      passengers: number;
    };

    const eventData = {
      ticket_booking_stage: "add dates​",

      trip_type: ticketsTripType,
      depart_date: gaDepartureDate,
      return_date: this.bookTicketJourneyType === "return" ? gaReturnDate : "",
      from_location: this.departure,
      to_location: this.destination,
      passengers: this.passengerCounter
    };

    (window as any).gtag(
      "event",
      "Initiate Tickets",
      eventData as BookingsGtagEventData
    );
  }
  public validateAndRedirectToCustomLinc(): void {
    this.bookTicketErrors = "";
    let departureCode = "";
    const departureCodes = this.locationsReferenceData.filter(
      x => x.text === this.departure
    );
    const type = "ADULT";

    if (!departureCodes || departureCodes.length === 0) {
      this.bookTicketErrors += `<div>From is required.</div>`;
    } else {
      departureCode = departureCodes[0].value;
    }

    let destinationCode = "";
    const destinationCodes = this.locationsReferenceData.filter(
      x => x.text === this.destination
    );
    if (!destinationCodes || destinationCodes.length === 0) {
      this.bookTicketErrors += `<div>To is required.</div>`;
    } else {
      destinationCode = destinationCodes[0].value;
    }

    const today = moment().startOf("day");
    if (!this.departureDate) {
      this.bookTicketErrors += `<div>Departure date is required.</div>`;
    } else if (today.isAfter(moment(this.departureDate))) {
      this.bookTicketErrors += `<div>Departure date cannot be in the past.</div>`;
    }

    if (this.bookTicketJourneyType === "return") {
      if (!this.returnDate) {
        this.bookTicketErrors += `<div>Return date is required.</div>`;
      } else {
        if (this.departureDate > this.returnDate) {
          this.bookTicketErrors += `<div>Return date cannot be before Departure date.</div>`;
        }
      }
    }

    if (!+this.passengerCounter) {
      this.bookTicketErrors += `<div>Number of Passenger is required.</div>`;
    }

    if (+this.passengerCounter < 0) {
      this.bookTicketErrors += `<div>Number of Passenger is required.</div>`;
    }

    if (+this.passengerCounter > 14) {
      this.bookTicketErrors +=
        `<div>To book a group of 15 or more people visit our ` +
        `<b><u><a href="/tickets-times/group-bookings">Group Bookings</a></u></b>` +
        ` page.</div>`;
    }

    if (!this.bookTicketErrors) {
      let customLincUrl = "";

      if (this.bookTicketJourneyType === "oneWay") {
        this.returnDate = "";
      }

      this.fireInitiateTicketsGAEvent();
      customLincUrl = `${this.customLincBookingsUrl}/fares-and-names?cart={"departure":{"fromLocation":"${departureCode}","toLocation":"${destinationCode}","fromDate":"${this.departureDate}","returnDate":"${this.returnDate}","passengers":${this.passengerCounter}},"fares":[{"code":"${type}","quantity":${this.passengerCounter}}]}`;

      window.open(customLincUrl, "_self");
    }
  }

  private fireInitiateJourneyPlannerGAEvent(): void {
    let gaDepartureDate: string = moment(this.departureDate).format(
      "DD/MM/YYYY"
    );
    let gaReturnDate: string = "";
    if (this.returnDate) {
      gaReturnDate = moment(this.returnDate).format("DD/MM/YYYY");
    }

    const jptripType =
      this.journeyPlannerJourneyType === "return" ? "return" : "one way";

    type JpGtagEventData = {
      journey_planner_stage: string;
      trip_type: string;
      depart_date: string;
      return_date: string;
      from_location: string;
      to_location: string;
      passengers: number;
    };

    const eventData = {
      journey_planner_stage: "add details",
      trip_type: jptripType,
      depart_date: gaDepartureDate,
      return_date:
        this.journeyPlannerJourneyType === "return" ? gaReturnDate : "",
      from_location: this.departure,
      to_location: this.destination,
      passengers: this.passengerCounter
    };
    (window as any).gtag(
      "event",
      "Initiate Journey Planner",
      eventData as JpGtagEventData
    );
  }

  public validateAndRedirectToJourneyPlanner(): void {
    this.journeyPlannerErrors = "";
    let departureCode = "";
    const departureCodes = this.locationsReferenceData.filter(
      x => x.text === this.departure
    );
    if (!departureCodes || departureCodes.length === 0) {
      this.journeyPlannerErrors += `<div>From is required.</div>`;
    } else {
      departureCode = departureCodes[0].value;
    }

    let destinationCode = "";
    const destinationCodes = this.locationsReferenceData.filter(
      x => x.text === this.destination
    );
    if (!destinationCodes || destinationCodes.length === 0) {
      this.journeyPlannerErrors += `<div>To is required.</div>`;
    } else {
      destinationCode = destinationCodes[0].value;
    }

    const today = moment().startOf("day");
    if (!this.departureDate) {
      this.journeyPlannerErrors += `<div>Departure date is required.</div>`;
    } else if (today.isAfter(moment(this.departureDate))) {
      this.journeyPlannerErrors += `<div>Departure date cannot be in the past.</div>`;
    }

    if (this.journeyPlannerJourneyType === "return") {
      if (!this.returnDate) {
        this.journeyPlannerErrors += `<div>Return date is required.</div>`;
      } else {
        if (this.departureDate > this.returnDate) {
          this.journeyPlannerErrors += `<div>Return date cannot be before Departure date.</div>`;
        }
      }
    }

    if (!+this.passengerCounter) {
      this.journeyPlannerErrors += `<div>Number of Passenger is required.</div>`;
    }

    if (+this.passengerCounter < 0) {
      this.journeyPlannerErrors += `<div>Number of Passenger is required.</div>`;
    }

    if (+this.passengerCounter > 14) {
      this.journeyPlannerErrors +=
        `<div>To book a group of 15 or more people visit our ` +
        `<b><u><a href="/tickets-times/group-bookings">Group Bookings</a></u></b>` +
        ` page.</div>`;
    }

    if (!this.journeyPlannerErrors) {
      const url = window.location.href;
      const arr = url.split("/");
      const domain = arr[2];
      const protocol = window.location.protocol;
      sessionStorage.setItem("departureCode_jp", departureCode);
      sessionStorage.setItem("departureDate_jp", this.departureDate);
      sessionStorage.setItem("destinationCode_jp", destinationCode);
      sessionStorage.setItem(
        "passengerCounter_jp",
        this.passengerCounter.toString()
      );
      let journeyPlannerUrl = `${protocol}//${domain}/plan-your-journey/journey-planner`;

      if (this.journeyPlannerJourneyType === "oneWay") {
        sessionStorage.removeItem("returnDate_jp");
      } else {
        sessionStorage.setItem("returnDate_jp", this.returnDate);
      }
      this.fireInitiateJourneyPlannerGAEvent();
      sessionStorage.setItem(
        "customLincUnavailable_jp",
        this.customLincUnavailable.toString()
      );
      sessionStorage.setItem("execute_search_jp", "true");
      window.open(journeyPlannerUrl, "_self");
    }
  }

  public validateAndRedirectToArrivalDeparture(): void {
    this.arrivalDepartureErrors = "";
    let stopLocation = "";
    const stopLocations = this.locationsReferenceData.filter(
      x => x.text === this.stopLocation
    );
    if (!stopLocations || stopLocations.length === 0) {
      this.arrivalDepartureErrors += `<div>Location is required.</div>`;
    } else {
      stopLocation = stopLocations[0].value;
    }

    if (!this.arrivalDepartureErrors) {
      const url = window.location.href;
      const arr = url.split("/");
      const domain = arr[2];
      const protocol = window.location.protocol;
      // const arrivalAndDepartureUrl = `${protocol}//${domain}/tickets-times/departures-and-arrivals/${this.arrivingDepartingType}/${stopLocation}`;
      const arrivalAndDepartureUrl = `${protocol}//${domain}/tickets-times/departures-and-arrivals`;

      sessionStorage.setItem(
        "arrivingDepartingType",
        this.arrivingDepartingType
      );
      sessionStorage.setItem("stopLocation", stopLocation);

      window.open(arrivalAndDepartureUrl, "_self");
    }
  }

  public increasePassenger(): void {
    if (this.passengerCounter < 14) {
      this.passengerCounter++;
    }
  }

  public decreasePassenger(): void {
    if (this.passengerCounter > 1) {
      this.passengerCounter--;
    }
  }

  public validatePassengerCounter(): void {
    if (this.passengerCounter) {
      if (+this.passengerCounter < 1) {
        this.passengerCounter = 1;
      }
      if (+this.passengerCounter > 14) {
        if (this.bookTicketErrors) {
          if (
            !this.bookTicketErrors.includes(
              `<div>To book a group of 15 or more people visit our ` +
                `<b><u><a href="/tickets-times/group-bookings">Group Bookings</a></u></b>` +
                ` page.</div>`
            )
          ) {
            this.bookTicketErrors +=
              `<div>To book a group of 15 or more people visit our ` +
              `<b><u><a href="/tickets-times/group-bookings">Group Bookings</a></u></b>` +
              ` page.</div>`;
          }
        } else {
          this.bookTicketErrors =
            `<div>To book a group of 15 or more people visit our ` +
            `<b><u><a href="/tickets-times/group-bookings">Group Bookings</a></u></b>` +
            ` page.</div>`;
        }

        if (this.journeyPlannerErrors) {
          if (
            !this.journeyPlannerErrors.includes(
              `<div>To book a group of 15 or more people visit our ` +
                `<b><u><a href="/tickets-times/group-bookings">Group Bookings</a></u></b>` +
                ` page.</div>`
            )
          ) {
            this.journeyPlannerErrors +=
              `<div>To book a group of 15 or more people visit our ` +
              `<b><u><a href="/tickets-times/group-bookings">Group Bookings</a></u></b>` +
              ` page.</div>`;
          }
        } else {
          this.journeyPlannerErrors =
            `<div>To book a group of 15 or more people visit our ` +
            `<b><u><a href="/tickets-times/group-bookings">Group Bookings</a></u></b>` +
            ` page.</div>`;
        }
      } else {
        if (this.journeyPlannerErrors) {
          this.journeyPlannerErrors = this.journeyPlannerErrors.replace(
            `<div>To book a group of 15 or more people visit our ` +
              `<b><u><a href="/tickets-times/group-bookings">Group Bookings</a></u></b>` +
              ` page.</div>`,
            ""
          );
        }

        if (this.bookTicketErrors) {
          this.bookTicketErrors = this.bookTicketErrors.replace(
            `<div>To book a group of 15 or more people visit our ` +
              `<b><u><a href="/tickets-times/group-bookings">Group Bookings</a></u></b>` +
              ` page.</div>`,
            ""
          );
        }
      }
    }
  }

  public searchDepartureArrival(): void {
    this.departureArrivalErrors = "";
    if (!this.location) {
      this.departureArrivalErrors += `<div>Please specify departing from or arriving at location.</div>`;
    }

    if (this.departureArrivalErrors) {
      return;
    }

    const origin = window.location.origin;
    const departureArrivalUrl = `${origin}/Arrivals-and-Departures?departureArrival=${this.departureArrival}&location=${this.location}`;
    window.open(departureArrivalUrl, "_self");
  }

  public showBookingWidget(): void {
    var journeyPlannerWidget = document.getElementById(
      "journey-planner-widget"
    );
    this.hideWidget(journeyPlannerWidget);

    var arrivalsDeparturesWidget = document.getElementById(
      "arrivals-departures-widget"
    );
    this.hideWidget(arrivalsDeparturesWidget);

    var bookingWidget = document.getElementById("booking-widget");
    this.showWidget(bookingWidget);
  }

  public showJourneyPlannerWidget(): void {
    var bookingWidget = document.getElementById("booking-widget");
    this.hideWidget(bookingWidget);

    var arrivalsDeparturesWidget = document.getElementById(
      "arrivals-departures-widget"
    );
    this.hideWidget(arrivalsDeparturesWidget);

    var journeyPlannerWidget = document.getElementById(
      "journey-planner-widget"
    );
    this.showWidget(journeyPlannerWidget);
  }

  public showArrivalsDeparturesWidget(): void {
    var bookingWidget = document.getElementById("booking-widget");
    this.hideWidget(bookingWidget);

    var journeyPlannerWidget = document.getElementById(
      "journey-planner-widget"
    );
    this.hideWidget(journeyPlannerWidget);

    var arrivalsDeparturesWidget = document.getElementById(
      "arrivals-departures-widget"
    );
    this.showWidget(arrivalsDeparturesWidget);
  }

  private showWidget(widget): void {
    if (widget && !widget.classList.contains("widget--visible")) {
      if (!this.isMobileView) {
        setTimeout(() => {
          widget.classList.add("widget--visible");
          window.onclick = function(event) {
            if (widget.contains(event.target as any)) return;
            if (
              (<HTMLInputElement>document.activeElement).hasAttribute(
                "data-has-picker"
              )
            )
              return; // date polyfill
            widget.classList.remove("widget--visible");
          };
        }, 100);
      }
    }
  }

  private hideWidget(widget): void {
    if (widget && widget.classList.contains("widget--visible")) {
      widget.classList.remove("widget--visible");
    }
  }

  private formatDateToYyyyMmDd(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  private checkForParams(): void {
    const urlParts = window.location.href.split("/");
    if (urlParts.length > 4 && urlParts[4] !== "#") {
      this.shrinkWidget = true;
    }
  }

  private setCustomLincAvailability(
    customLincUnAvailableFromDate: string,
    customLincUnAvailableFromTime: string,
    customLincUnAvailableToDate: string,
    customLincUnAvailableToTime: string
  ): void {
    let customLincUnavailable = false;
    if (
      customLincUnAvailableFromDate &&
      customLincUnAvailableFromTime &&
      customLincUnAvailableToDate &&
      customLincUnAvailableToTime
    ) {
      const fromDate = moment(
        `${customLincUnAvailableFromDate} ${customLincUnAvailableFromTime}`,
        "DD-MM-YYYY hh:mm a"
      );
      const toDate = moment(
        `${customLincUnAvailableToDate} ${customLincUnAvailableToTime}`,
        "DD-MM-YYYY hh:mm a"
      );
      const currentDateTime = moment();

      // const aaa = fromDate.isBefore(toDate);
      // console.log('aaa: ', aaa);
      // const bbb = currentDateTime.isAfter(fromDate);
      // console.log('bbb: ', bbb);
      // const ccc = currentDateTime.isBefore(toDate);
      // console.log('ccc: ', ccc);

      if (
        fromDate &&
        toDate &&
        fromDate.isBefore(toDate) &&
        currentDateTime.isAfter(fromDate) &&
        currentDateTime.isBefore(toDate)
      ) {
        customLincUnavailable = true;
      }
    }

    this.customLincUnavailable = customLincUnavailable;
    // sessionStorage.setItem('customLincUnavailable', this.customLincUnavailable.toString());
  }

  public departureDateChanged(index: number): void {
    var element = document.getElementsByName("departureDate")[index];
    element.dispatchEvent(new Event("input"));

    this.departureDate = (<HTMLInputElement>element).value;

    if (
      this.departureDate.match(
        /(([1-2][0-9])|0([1-9])|(3[0-1]))\/((1[0-2])|0([1-9]))\/[0-9]{4}/
      )
    ) {
      this.departureDate = moment(this.departureDate, "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      );
    } else {
      if (
        !this.departureDate.match(
          /[0-9]{4}-((1[0-2])|0([1-9]))-(([1-2][0-9])|0([1-9])|(3[0-1]))/
        )
      ) {
        this.departureDate = "";
      }
    }
  }

  public returnDateChanged(index: number): void {
    var element = document.getElementsByName("returnDate")[index];
    element.dispatchEvent(new Event("input"));

    this.returnDate = (<HTMLInputElement>element).value;

    if (
      this.returnDate.match(
        /(([1-2][0-9])|0([1-9])|(3[0-1]))\/((1[0-2])|0([1-9]))\/[0-9]{4}/
      )
    ) {
      this.returnDate = moment(this.returnDate, "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      );
    } else {
      if (
        !this.returnDate.match(
          /[0-9]{4}-((1[0-2])|0([1-9]))-(([1-2][0-9])|0([1-9])|(3[0-1]))/
        )
      ) {
        this.returnDate = "";
      }
    }
  }

  public setDepartingStop(index: number): void {
    var element = document.getElementsByName("departingFromInput")[index];
    element.dispatchEvent(new Event("input"));

    this.departure = (<HTMLInputElement>element).value;
  }

  public setDestinationStop(index: number): void {
    var element = document.getElementsByName("travellingToInput")[index];
    element.dispatchEvent(new Event("input"));

    this.destination = (<HTMLInputElement>element).value;
  }

  public setStopLocation(): void {
    var element = document.getElementById("stopLocationInput");
    element.dispatchEvent(new Event("input"));

    this.destination = (<HTMLInputElement>element).value;
  }

  public showDisruptionMessage(): boolean {
    return (
      this.isDisruption &&
      this.disruptionMessage &&
      ((this.departure && this.departure === this.disruptionLocation) ||
        (this.destination && this.destination === this.disruptionLocation))
    );
  }
}
