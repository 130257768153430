







import { Component, Prop, Vue } from "vue-property-decorator";
import Home from "../src/views/Home.vue";

@Component({
  components: {
    Home
  }
})
export default class App extends Vue {
  public isGoogleMapLoaded = false;
}
