import axiosSso from "./axios-sso";
import axios from "axios";

const state = {
  locations: [],
  browsingOnMobile: false
};

const getters = {
  fetchLocations: state => state.locations,
  userAgentIsMobile: state => state.browsingOnMobile
};

const actions = {
  async fetchLocations({ commit }) {
    // const response = await axiosSso.axiosOtterSso.get('api/location/get');
    const { config, url } = (<any>window).dnninjectable.locationApi;
    // const urlTemp = 'API/HeroBanner/service/locations';
    const response = await axios.get(url, config);
    commit("setLocations", response.data);
    return response.data;
  },
  userAgentIsMobile: ({ commit }) => {
    commit("setBrowsingOnMobile");
  }
};

const mutations = {
  setLocations: (state, locations) => {
    state.locations = locations;
  },
  setBrowsingOnMobile: state => {
    state.browsingOnMobile = true;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
