






import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import TopNavigation from "../components/top-navigation/TopNavigation.vue";

@Component({
  components: {
    TopNavigation
  }
})
export default class Home extends Vue {
  public isAdmin = false;

  public created(): void {}
}
